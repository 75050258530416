import React, { useCallback, useRef, useState, useEffect } from 'react';

import { FaLock, FaAddressCard } from 'react-icons/fa';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import getValidationErrors from 'utils/getValidationErrors';

import { Container } from 'styles/wrappers';

import Header from 'components/Header';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import ScrollTop from 'components/ScrollTop';
import Input from 'components/Input';
import Button from 'components/Button';
import Loading from 'components/Loading';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useChanges } from 'hooks/changes';

import api from 'services/api';
import { Content, ForgotButton, Version } from './styles';
// import { Content, Version } from './styles';

interface FormData {
  cert: string;
  pass: string;
}

interface PassData {
  cert: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { closed, end } = useChanges();

  const [type, setType] = useState(true);
  const formRef = useRef<FormHandles>(null);

  const hasStatus = useCallback(() => {
    if (search.substr(0, 8) === '?status=') {
      const code = search.replace('?status=', '');
      let message = '';
      if (code === '400') {
        message = 'Combinação usuário / senha inválida';
      } else if (code === '401') {
        message = 'Acesso expirado';
      } else if (code === '403') {
        message = 'Usuário não autorizado';
      } else if (code === '404') {
        message = 'Token não encontrado';
      } else if (code === '422') {
        message = 'Acesso inválido';
      } else if (code === '500') {
        message = 'Erro interno. Acione o suporte ao SGO';
      } else {
        message = 'Erro interno. Acione o suporte ao SGO';
      }

      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: message,
      });
      history.replace('/signin');
    }
  }, [search, history, addToast]);

  useEffect(() => {
    hasStatus();
  }, [hasStatus]);

  const changeTypeOfSubmit = useCallback(() => {
    setType(!type);
    setTimeout(() => formRef.current?.submitForm(), 100);
  }, [type]);

  const handleClosed = useCallback(() => {
    localStorage.removeItem(`${process.env.REACT_APP_USER}`);
    localStorage.removeItem(`${process.env.REACT_APP_TOKEN}`);

    addToast({
      type: 'info',
      title: 'Informação',
      description: `O SGO está fechado para acesso. As atividades serão retomadas a partir de ${end}`,
      seconds: 15,
    });
  }, [addToast, end]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        if (
          closed &&
          ['amanda', 'anderson', 'arthur', 'zank'].indexOf(data.cert) < 0
        ) {
          handleClosed();
          return;
        }
        // if (['1', '2', '3', '4'].indexOf(data.cert) > -1) {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          cert: yup
            .string()
            .trim()
            .required('N° certificado é obrigatório')
            .max(11, 'Quantidade máxima de caracteres excedida (11)'),
          pass: yup.string().trim().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn({
          certificado: data.cert,
          password: data.pass,
        });

        setLoading(false);
        // } else {
        //   localStorage.removeItem(`${process.env.REACT_APP_USER}`);
        //   localStorage.removeItem(`${process.env.REACT_APP_TOKEN}`);

        //   addToast({
        //     type: 'info',
        //     title: 'Informação',
        //     description:
        //       'O SGO está fechado para acesso. As atividades serão retomadas em 15/02/2021',
        //     seconds: 15,
        //   });
        // }
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        // errorHandling(err);
      }
    },
    [closed, handleClosed, signIn],
  );

  const handleForgotPass = useCallback(
    async (data: PassData) => {
      try {
        setType(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          cert: yup
            .string()
            .trim()
            .required('N° certificado é obrigatório')
            .max(11, 'Quantidade máxima de caracteres excedida (11)'),
        });

        await schema.validate(data, { abortEarly: false });

        const send = new FormData();
        // send.append('cert', data.cert.padStart(6, '0'));
        send.append('cert', data.cert);

        const response = await api.post(
          '/common/send_password_email.php',
          send,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );

        const { status, message } = response.data;

        if (status) {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: message,
          });

          return;
        }

        addToast({
          type: 'info',
          title: 'Email enviado',
          description: 'Um email foi enviado para o endereço cadastrado.',
        });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast],
  );

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <Header />
      <Navbar />
      <Content>
        <Form ref={formRef} onSubmit={type ? handleSubmit : handleForgotPass}>
          <h1>Faça seu login:</h1>
          <Input
            icon={FaAddressCard}
            name="cert"
            placeholder="N° Certificado"
            // type="number"
          />
          <Input
            icon={FaLock}
            name="pass"
            type="password"
            placeholder="Senha"
            isPass
          />
          <Button type="submit" containerStyle={{ marginTop: '25px' }}>
            Entrar
          </Button>
          <ForgotButton type="button" onClick={changeTypeOfSubmit}>
            Esqueci minha senha
          </ForgotButton>
          <Version>
            <strong>v</strong>
            <p>{process.env.REACT_APP_VERSION}</p>
          </Version>
        </Form>
      </Content>
      <Footer />
    </Container>
  );
};

export default SignIn;

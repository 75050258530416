import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 110px;
  padding: 5px 10px;

  > div {
    display: flex;
    gap: 0 15px;
    margin: 10px 0;
  }

  h3 {
    color: #8a0002;
    font-size: 22px;
    text-shadow: 1px 1px 1px #767676;
  }

  button {
    display: flex;
    padding: 3px 7px;

    border: 0;
    background: transparent;

    transition: color 0.35s ease;
    svg {
      color: #262626;
      margin: 0 7px 0 7px;
      height: 20px;
      width: 20px;
      transition: color 0.35s ease;
    }

    p {
      color: #262626;
      font-size: 16px;
      text-shadow: 1px 1px 1px #767676;
      text-decoration: underline;
      transition: color 0.35s ease;
    }

    &:hover {
      p {
        color: #1a237e;
      }
      svg {
        color: #464646;
      }
    }
  }

  @media screen and (max-width: 675px) {
    > div {
      flex-direction: column;
      gap: 10px 0px;
    }
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  gap: 0 15px;
  /* padding: 0 10px; */

  > div {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    max-height: calc(100vh - 300px);
    overflow-y: auto;

    > div {
      display: flex;

      /* height: 200px; */
      /* background: #fff; */
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column;
    gap: 15px 0;
    div {
      max-height: unset;
      height: auto;
    }
  }
`;

export const ASide = styled.div`
  width: 40%;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  ::-webkit-scrollbar-thumb {
    background: #8a0002;
    border-radius: 5px;

    &:hover {
      background: #c53030;
    }
  }

  @media screen and (max-width: 675px) {
    margin: 0 auto;
    width: 400px;
  }

  @media screen and (max-width: 400px) {
    width: 340px;
  }
`;
export const BSide = styled.div`
  padding: 10px 15px;
  width: 60%;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 675px) {
    margin: 0 auto 20px auto;
  }
`;

export const Details = styled.details`
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  font-size: 16px;
  font-family: 'Roboto Slab', serif;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 95%;
  background: #fff;
  margin: 10px auto 0;

  border: 2px solid #e6e6e6;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 2px 5px 10px 3px rgba(0, 0, 0, 0.5);

  &:nth-last-child(1) {
    margin: 10px auto 15px;
  }

  summary {
    /* font-size: 16px; */
    text-shadow: 1px 1px 1px #767676;
    font-weight: 500;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    margin-bottom: 5px;

    svg {
      /* position: absolute;
      right: 5px;
      top: 1px; */
      will-change: transform, color;
      transition: 0.25s ease;
      margin-left: 0.438rem;
      color: #43a047;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &::before {
      /* content: attr(data-close); */
    }
  }

  &[open] {
    border-color: #332e2e;
    summary {
      svg {
        transform: rotate(45deg);
        color: #c53030;
      }
      &::before {
        /* content: attr(data-open); */
      }
    }
  }
`;

export const SubTrack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: auto;
  font-size: 14px;

  /* border-bottom: 1px solid #d6d6d6; */

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: transparent;
    border: 0px solid transparent;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  span {
    & + span {
      margin: 0 0 0 10px;
    }
  }

  &:after {
    position: absolute;
    content: '';
    border: 1px solid #e6e6e6;
    width: 98%;
    bottom: -5px;
    left: 0;
  }

  & + div {
    margin: 7px 0 0 0;
  }

  &:nth-last-child(1) {
    &:after {
      border: 0px solid transparent;
    }
  }
`;

export const PlayerContainer = styled.div`
  border-radius: 10px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* overflow: hidden; */
  p {
    font-size: 20px;
  }
`;

export const PlayerWrapper = styled.div`
  /* margin: 0 auto; */
`;

export const ProgressDiv = styled.div`
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  width: 22.5rem;
  min-height: 100px;
  progress[value] {
    margin: 0 auto;
    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    height: 20px;

    position: relative;

    > p {
      position: absolute;
    }

    &::-webkit-progress-bar {
      background-color: #eee;
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    &::-webkit-progress-value {
      background-image: -webkit-linear-gradient(
          45deg,
          transparent 33%,
          rgba(0, 0, 0, 0.1) 33%,
          rgba(0, 0, 0, 0.1) 66%,
          transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
        -webkit-linear-gradient(left, #8a0002, #f44);

      border-radius: 3px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    }
  }
`;
